@font-face {
  font-family: 'YanoneKaffeesatz-Bold';
  src: url('/fonts/YanoneKaffeesatz-Bold/YanoneKaffeesatz-Bold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/YanoneKaffeesatz-Bold/YanoneKaffeesatz-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/YanoneKaffeesatz-Bold/YanoneKaffeesatz-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/YanoneKaffeesatz-Bold/YanoneKaffeesatz-Bold.woff') format('woff'), /* Pretty Modern Browsers */
  url('/fonts/YanoneKaffeesatz-Bold/YanoneKaffeesatz-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/YanoneKaffeesatz-Bold/YanoneKaffeesatz-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'YanoneKaffeesatz-Regular';
  src: url('/fonts/YanoneKaffeesatz-Regular/YanoneKaffeesatz-Regular.eot'); /* IE9 Compat Modes */
  src: url('/fonts/YanoneKaffeesatz-Regular/YanoneKaffeesatz-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/YanoneKaffeesatz-Regular/YanoneKaffeesatz-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/YanoneKaffeesatz-Regular/YanoneKaffeesatz-Regular.woff') format('woff'), /* Pretty Modern Browsers */
  url('/fonts/YanoneKaffeesatz-Regular/YanoneKaffeesatz-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/YanoneKaffeesatz-Regular/YanoneKaffeesatz-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: bold;
  font-style: normal;
}
html {
  position: relative;
  min-height: 100%;
}
body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #3c4858;
  margin: 0 0 100px 0;
}
body.contact-us {margin: 0 0 79px 0;}
body.no-bg, body.ads, body.websites, body.adsCampaigns {
  background: #ffffff;
}
body.downline {
  background: {
    image: none;
    color: #ffffff;
  };
}

.navbar {background: #FFFFFF; border: none; margin: 0; border-radius: 0}
.navbar.loggedIn {background: #2980b9}
.loggedIn .logo {
  background: url("/img/logoWhite.png");
}
.logo {
  background-size: contain;
  height: 40px;
  background: url("/img/logo.jpg");
  background-repeat: no-repeat;
  margin: 20px 0;
  width: 172px
}
.flex-start {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-start.start-container {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.footer-container {}
.flex-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.flex-content {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.justify-content-flex-end {
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.flex-wrap-nowrap {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.logoZ {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.menu-nav {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
  display: flex;
  align-items: center;
}
.navbar-default.loggedIn .menu-nav a.btn-link  {
  color: #ffffff;
}
.navbar-default .menu-nav a.btn-link  {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #3c4858;
}
#footer {
  background: url("/img/ft-bg.jpg");
  background-repeat: repeat;
  border: none;
  bottom: 0;
  position: absolute;
  width: 100%;
  z-index: 3;
}
.footer-nav {text-align: center; padding: 30px 0; }
.footer-nav ul {
  padding: 0;
  margin: auto;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.footer-nav ul li {list-style: none; display: flex; align-items: center}
.footer-nav ul li:first-child::before {content: "" !important;}
.footer-nav ul li:nth-child(odd)::before {
  content: "•";
  color: #2980b9;
  padding: 0 10px;
}
.footer-nav ul li:nth-child(even)::before {
  content: "•";
  color: #27ae60;
  padding: 0 10px;
}
.footer-nav ul li a {
  color: #7b8aa4;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase
}
h1.page-title {
  color: #3c4858;
  font-size: 92px;
  font-family: 'YanoneKaffeesatz-Bold';
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}
#particles-js.headParticles {
  height: 100px;
  background-color: transparent;
  background-image: url(/img/bg.jpg);
  background-size: cover;
  background-position: center 10%;
  background-repeat: no-repeat;
  position: static;
}
h3.heading-title {
  color: #3c4858;
  font-size: 48px;
  font-family: 'YanoneKaffeesatz-Bold';
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  height: 100px;
  line-height: 110px;
  width: 100%;
  position: absolute;
}
h2.page-subTitle.gray-text {
  color: #8492a7;
  font-family: 'YanoneKaffeesatz-Regular';
}
h2.page-subTitle {
  color: #3c4858;
  font-family: 'YanoneKaffeesatz-Bold';
  display: block;
  margin: 0;
}
h3.page-subTitle {
  color: #3c4858;
  font-family: 'YanoneKaffeesatz-Bold';
}
h3.page-title {
  color: #3c4858;
  font-size: 48px;
  font-family: 'YanoneKaffeesatz-Bold';
  margin: 0;
  text-align: center;
  flex-grow: 1;
}
h4.form-title {
  color: #3c4858;
  font-size: 30px;
  font-family: 'YanoneKaffeesatz-Bold';
  margin: 0;
  text-align: center;
}
h5.not-registered a, h5.sign-in a {
  color: #2980b9;
  text-decoration: underline;
}
h5.not-registered {
  color: #8492a6;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  margin: 10px 0 40px 0;
  text-align: center;
}
h5.sign-in {
  color: #8492a6;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  margin: 0;
  text-align: center;
}
.textUp {text-transform: uppercase}
.panel-body {padding: 0}
.search .container.middle {margin-top: 15%;}
.form-control {
  height: 45px;
  padding: 5px 45px 5px 10px;
  color: #8492a6;
  border: none;
  box-shadow: 0px 4px 14.6625px 0.3375px rgba(0, 0, 0, 0.09);
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
input[type="checkbox"].form-control {
  height: 25px;
  width: 25px;
}
#form-search .form-control {
  box-shadow: none;
}
#search-form {margin-top: 45px}
#search-form input[type='submit'] {}
#search-form input[type="submit"].disabled {cursor: not-allowed}
#search-form #btn-search-voice {
  min-width: 45px;
  width: 45px;
  height: 45px;
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  box-shadow: none;
}
#search-form #btn-search-voice:before {
  font-family: FontAwesome;
  content: "\f130 ";
  font-size: 21px;
  color: #c0ccda;
}
#search-form #btn-search-voice.active:before {
  color: #FF0000;
  font-family: FontAwesome;
  content: "\f130 ";
  font-size: 21px;
}
#search-form .rumPopup {margin-left: 5px}
#btn-search-voice .tooltip{
  position: fixed;
  padding: 10px 13px;
  z-index: 2;
  color: #303030;
  font-family: "Gotham Rounded Bold";
  background-color: #f5f5b5;
  border: 1px solid #DECA7E;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  opacity: 1;
  letter-spacing: 0.0025em;
}
.panel {
  background-color: transparent;
  border: none;
  box-shadow: none;

  &.web-review {
    .flex-content {
      flex-wrap: wrap;

      @media screen and (min-width: 768px) {
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      .contentTypeTitle {
        flex-basis: 90%;

        @media screen and (min-width: 768px) {
          flex-basis: auto;
        }
      }

      .addthis_inline_share_toolbox {
        margin: 10px 0;

        @media screen and (min-width: 768px) {
          padding-left: 30px;
          margin: 0;
        }
      }

      .share-user {
        position: relative;
        width: 100%;
        max-width: none;
        height: 44px;
        margin-bottom: 10px;

        @media screen and (min-width: 768px) {
          margin-left: 10px;
          margin-bottom: 0;
          max-width: 200px;
        }

        .searchable {
          margin: 0;
        }

        a {
          margin: 0;
        }
      }
    }
  }
}

.form-input {
  width: 100%;
  position: relative;
}
/*------RightMenu------*/
body.has-active-menu {
  overflow: hidden;
}
.loggedIn .c-button {
  background: url("/img/toggleWhite.png");
}
.c-button {
  background: url("/img/toggle.png");
  width: 28px;
  height: 19px;
  border: none;
  font-size: 0;
  margin-left: 12px;
}
.c-button:focus {
  outline: none;
}
.c-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.c-menu--slide-right.is-active {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.c-menu--slide-right {
  padding: 30px;
  width: 400px;
  height: 100%;
  overflow-y: auto;
  top: 0;
  right: 0;
  -webkit-transform: translateX(400px);
  -ms-transform: translateX(400px);
  transform: translateX(400px);
}
.c-menu__item {
  display: block;
  border-bottom: solid 1px #086e35;
}
.c-menu__item:first-child {
  border-top: solid 1px #086e35;
}
.c-menu__link {
  display: block;
  padding: 20px 0;
  color: #fff;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 16px;
}
.c-menu {
  position: fixed;
  z-index: 300;
  background: {
    image: url("/img/menuBg.jpg");
    size: cover;
    repeat: no-repeat;
    position: center top;
  };
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
}
.c-menu__items {
  list-style: none;
  margin: 30px 0 0 0;
  padding: 0;
}
.c-menu__name {
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
}
i.fa.fa-times {
  font-size: 22px;
}
.c-menu__close {
  color: #fff;
  background-color: transparent;
  font-size: 14px;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
.c-menu__close:focus {
  outline: none;
}
.c-mask {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 0;
  height: 0;
  background-color: #000;
  opacity: 0;
  -webkit-transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
  transition: opacity 0.3s, width 0s 0.3s, height 0s 0.3s;
}
.c-mask.is-active {
  width: 100%;
  height: 100%;
  opacity: 0.7;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
/*------RightMenu------*/
.button {
  box-shadow: 0 4px 12.75px 2.25px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  font-size: 16px;
  line-height: 44px;
  font-family: 'Lato', sans-serif;
  border: none;
  font-weight: 700;
  text-transform: uppercase;
  outline: none;
  padding: 0 21px;
  margin: 3px;
}
a.buttonBg {
  display: inline-block;

  &:hover {
    text-decoration: none;
  }
}
.buttonBg {
  background: #0cca5b;
  background: -moz-linear-gradient(-45deg, #0cca5b 0%, #0c85d6 100%);
  background: -webkit-linear-gradient(-45deg, #0cca5b 0%,#0c85d6 100%);
  background: linear-gradient(135deg, #0cca5b 0%,#0c85d6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0cca5b', endColorstr='#0c85d6',GradientType=1 );
  color: #f8faff;
}
.buttonNoBg {
  color: #3c4858;
  background-color: #f8faff;
}
.buttonBlock {display: block; width: 100%}
.button-block {
  display: block;
  margin: auto;
}
#search-form button {
  margin-left: 25px;
  padding: 0;
}
#search-form button.is-active {
  background: {
    color: #f9fafc;
  };
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
#search-form button span .fa {
  font-size: 21px;
  color: #3c4858;
  padding-right: 12px;
}
#search-form button span {padding: 0 25px;}
#search-form button.is-active span {box-shadow: 0 15px 0 #f9fafc;}
#trending-buttons {
  display: none;
  margin-top: 10px;
  background: {
    color: #f9fafc;
  };
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 4px 12.75px 2.25px rgba(0, 0, 0, 0.09);
}
.trendingContent {
  padding: 20px;
}
.siteOpt {
  display: block;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #8492a6;
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 700;
}
.chkBox > input[type="checkbox"] {
  display: none;
}

.chkBox > label {
  cursor: pointer;
  height: 0;
  margin: 0;
  position: relative;
  width: 30px;
}

.chkBox > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 5px;
  margin-top: -7px;
  position:absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 30px;
}
.chkBox > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 16px;
  left: 0;
  margin-top: 0;
  position: absolute;
  top: -13px;
  transition: all 0.3s ease-in-out;
  width: 16px;
}
.chkBox > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.chkBox > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 15px;
}
.option {
  height: 30px;
  padding: 5px 15px;
  background: #e5e9f2;
  border-radius: 25px;
  display: inline-block;
  margin-right: 20px;
}
.option.clicked {
  background: #2980b9;
}
.chkName {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  display: inline-block;
  color: #3c4858;
}
.clicked .chkName {
  color: #ffffff;
}
.clicked .label-default {
  background-color: #ffffff;
}
.chkBox {
  display: inline-block;
  margin-left: 15px;
}
.friendly {
  margin-bottom: 30px;
}
.trending-btn.btn {
  font-size: 14px;
  margin: 0 30px 30px 0;
  padding: 5px 15px;
  border-radius: 25px;
  background: #e5e9f2;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #3c4858;
}
.trending-btn.btn:focus,
.trending-btn.btn:hover {
  background: {
    color: #27ae60
  };
  color: #fff;
  box-shadow: none;
  outline: none;
}
.trending-btn.btn.active:before {
  font-family: FontAwesome;
  content: "\f00c";
  margin-right: 10px;
}
.trending-btn.btn.active {
  color: #fff;
  background-color: #27ae60;
  box-shadow: none;
  outline: none;
}
.footer_trending {
  background: {
    color: #eff2f7;
  };
  padding: 15px 20px;
}
.found_results span {
  font-size: 14px;
  color: #3c4858;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.submitBtn {
  position: relative;
  margin-left: 25px;
}
.submitBtn .fa-search {
  position: absolute;
  color: #ffffff;
  left: 20px;
  top: 50%;
  margin-top: -8px;
  font-size: 18px;
}
.btn-search:before {
  font-family: FontAwesome;
  content: "\f002";
  font-size: 18px;
  color: #ffffff;
}
.website-details:first-child {padding-right: 15px}
.website-details a.view-review {
  padding: 14px 25px !important;
  margin-right: 15px;
  font-size: 14px;
}
.website-details.flex-box {
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.clearFilters {
  margin-right: 30px;
  outline: none;
  border: none;
  height: 45px;
  line-height: 45px;
  background: none;
  font-size: 13px;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: #8492a6;
  padding: 0;
}
.clearFilters:focus, .clearFilters:active {
  outline: none !important;
  box-shadow: none !important;
}
.clearFilters:hover {
  color: #8492a6;
}
.clearFilters:before {
  font-family: FontAwesome;
  content: "\f00d";
  margin-right: 10px;
  font-size: 14px;
  color: #8492a6;
}
.clearFilters span {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #8492a6;
  text-transform: uppercase;
}
.panel-body.overlay, .search-result.overlay {
  /*background-color: #ffffff;*/
  border-radius:15px;
}
.list-group.search-results {
  background: #fff;
}
.list-group.search-results a.list-group-item {
  padding: 0;
}
.list-group-item h4.list-group-item-heading {
  color: #3c4858;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
}
.list-group-item p.list-group-item-text {
  color: #3c4858;
  font-family: 'Montserrat', sans-serif;
  margin: 0 0 15px 0;
}
.inline-ad {
  color: #8492a6;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  word-wrap: break-word;
}
.rate:hover, .rate:focus {text-decoration: none}
.pointSep:after {
  content: "•";
  color: #d3dce6;
  padding: 0 10px;
}
.rate.flex-start {
  color: #8492a6;
  font-family: 'Montserrat', sans-serif;
}
.rate i {margin-right: 10px; color: #d3dce6; font-size: 18px}
.rate i.fa.fa-reply {color: #8492a6}
.rate i.fa.fa-thumbs-o-up {margin-left: 10px}
.rate i.fa.fa-thumbs-o-down {margin-right: 0}
.rate .scores i.fa.fa-thumbs-o-up {margin-left: 0}
.rate .scores i.fa.fa-thumbs-o-down {margin-right: 10px}
.ads-item .list-group-item h4.list-group-item-heading {color: #03b039; word-wrap: break-word;}
.fads-item .list-group-item h4.list-group-item-heading, .app-item .list-group-item h4.list-group-item-heading {color: #f58023}
.submited-item .list-group-item h4.list-group-item-heading {color: #1fb6ff}
.result-item {
  padding: 30px;
  box-shadow: inset 0 45px 30px -35px rgba(0, 0, 0, 0.15);
}
.list-group.search-results:first-child .result-item:first-child {
  box-shadow: none;
}
.result-item:hover {
  background: #3b86bb;
  margin-right: -15px;
  margin-left: -15px;
  border-radius: 15px;
}
.result-item:hover .list-group-item h4.list-group-item-heading,
.result-item:hover .list-group-item p.list-group-item-text,
.result-item:hover .inline-ad,
.result-item:hover .rate.flex-start,
.result-item:hover .hashtags-box .read-more-wrap a,
.result-item:hover .hashtags-box label,
.result-item:hover .rate i {
  color: #ffffff;
}
.resultItemWrapper {
  max-width: 1050px;
  margin: auto;
}
.paid-ad, .app-src, .paid-fad, .app-submited {
  color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  font-size: 10px;
  text-transform: uppercase;
}
.paid-ad {
  background: #03b039;
}
.app-src, .paid-fad {
  background: #ff9805;
}
.app-submited {
  background: #1fb6ff;
}
.socialM .status {display: none; margin-right: 10px;}
.status-good .socialM .status {
  display: block;
  background: {
    image: url("/img/mF.png");
    repeat: no-repeat;
  };
  width: 124px;
  height: 20px;
}
.status-good:hover .socialM .status {
  background: {
    image: url("/img/mFW.png");
  };
}
.status-bad .socialM .status,
.status-bad-icon {
  display: block;
  background: {
    image: url("/img/nonMF.png");
    repeat: no-repeat;
  };
  width: 146px;
  height: 20px;
}
.status-bad:hover .socialM .status {
  background: {
    image: url("/img/nonMFW.png");
  };
}
#div-btn-more {
  margin: 50px 0;
  text-align: center;
}
#btn-more {
  background: #f8faff;
  color: #3c4858;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  height: 45px;
  display: inline-block;
  padding: 12px 15px;
  border-radius: 4px;
  box-shadow: 0 4px 12.75px 2.25px rgba(0, 0, 0, 0.09);
}
#btn-more:hover {text-decoration: none}
i.fa.fa-refresh {
  margin-right: 20px;
}
.pre-register {
  max-width: 560px;
  width: 100%;
  margin: auto;
}
.sign-in .contentWrapper {
  max-width: 560px;
  width: 100%;
  margin: auto;
  background: #f9fafc;
  box-shadow: 0 4px 12.75px 2.25px rgba(0, 0, 0, 0.09);
  padding: 50px 100px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -211px;
  margin-left: -280px;
}
.forgot .contentWrapper {
  max-width: 560px;
  width: 100%;
  margin: auto;
  background: #f9fafc;
  box-shadow: 0 4px 12.75px 2.25px rgba(0, 0, 0, 0.09);
  padding: 50px 100px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -162px;
  margin-left: -280px;
}
.contentWrapper {
  background: #f9fafc;
  box-shadow: 0 4px 12.75px 2.25px rgba(0, 0, 0, 0.09);
  padding: 50px 100px;
  border-radius: 4px;
}
.contentWrapper p {color: #8492a6}
.contentWrapper p strong {color: #3c4858}
.contentWrapper p strong.green {color: #086e35}

.panel-body form .form-group .col-md-12 .not-bad:before,
.panel-body form .form-group .col-md-12 .bad:before {
  font-size: 16px;
  position: absolute;
  right: 25px;
  top: 12px;
}
.panel-body form .form-group .col-md-12 .bad:before {
  content:  "X";
  color: #ff0000;
}
.panel-body form .form-group .col-md-12 .not-bad:before {
  content:  "\2713";
  color: #008000;
}
.m-b-15 {margin-bottom: 15px !important;}
.m-b-20 {margin-bottom: 20px !important;}
.m-b-30 {margin-bottom: 30px !important;}
.m-b-40 {margin-bottom: 40px !important;}
.m-b-0 {margin-bottom: 0 !important;}
.m-b-10 {margin-bottom: 10px !important;}
.m-t-10 {margin-top: 10px !important;}
.m-t-20 {margin-top: 20px !important;}
.m-t-30 {margin-top: 30px !important;}
.m-t-40 {margin-top: 40px !important;}
.m-t-50 {margin-top: 50px !important;}
.m-l-30 {margin-left: 30px !important;}
.p-l-50 {padding-left: 50px !important;}
.h-153 {height: 153px}
.form-control::-moz-placeholder {
  color: #8492a6;
  opacity: 1
}
.form-control:-ms-input-placeholder {
  color: #8492a6
}
.form-control::-webkit-input-placeholder {
  color: #8492a6
}
.text-danger, .msg-error {
  font-size: 12px;
  font-family: 'Lato', sans-serif;
  color: #a94442;
}
.forgot .col-md-12, .sign-in .col-md-12 {position: static}
.form-horizontal {position: relative}
hr.style {margin: 50px 0; border: 1px solid #e5e9f2}
.form-group {margin: 0 0 20px}
.for-captcha {
  margin: auto;
  max-width: 560px;
  width: 100%;
}
.for-captcha > img {
  width: 100%;
}
.captcha-box {margin-bottom: 10px}
.forgotOption {margin: 0 0 40px; padding: 0;}
.forgotOption li {display: table; list-style: none}
.forgotOption li:last-child:before {
  content: "•";
  color: #c0ccda;
  padding: 0 10px;
}
.forgotOption li a {text-decoration: underline}
.breadcrumb {background: none; padding: 20px 0; margin: 0}
.breadcrumb li {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  color: #333333;
  text-transform: uppercase;
}
.breadcrumb li a {
  color: #acacac;
}
.url-affiliate, .user-commission {
  text-align: center;
  margin-bottom: 25px;
}
.url-affiliate a {
  color: #27ae60;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 700;
}
.user-commission a {
  color: #ff4949;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 700;
}
.url-affiliate img, .user-commission img {
  display: block;
  margin: auto
}
.url-affiliate span, .user-commission span {
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  color: #3c4858;
  font-size: 24px;
  font-weight: 700;
  display: block;
  margin: 20px 0;
}
.affiliates {
  width:  100%;
}
.affiliates thead tr, .affiliates tbody tr {
  border-bottom: 1px solid #c0ccda;
}
.affiliates thead tr th {
  color: #8492a6;
}
.affiliates tbody tr td span {
  color: #3c4858;
  word-break: break-all;
}
.affiliates thead tr th, .affiliates tbody tr td {
  padding: 10px;
}
.levels {margin: 25px 0; display: inline-block; width: 100%}
.levels h5 {
  color: #3c4858;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  background: #e5e9f2;
  padding: 16px;
  margin: 0;
  border-bottom: 1px solid #8492a6;
}
.levels h5 span {
  color: #27ae60;
  font-weight: 700;
}
li.active .bottomActive {
  display: block;
  height: 8px;
  position: absolute;
  bottom: -4px;
  border-radius: 4px;
  background: #27ae60;
  width: 100%;
}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  background: none;
  border: none;
  color: #3c4858;
  font-weight: 700;
}
.nav-tabs > li > a, .nav-tabs > li > a:hover, .nav-tabs > li > a:focus, .nav-tabs > li > a:active {
  margin: 0;
  padding: 0 0 15px;
  background: none;
  border: none;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #8492a6;
}
.nav-tabs > li:first-child {margin-left: 0}
.nav-tabs > li {margin: 0 15px}
.review-heading {
  position: relative;
}
.review-heading #form-search {
  position: absolute;
  right: 0;
  width: 200px;
  top: -50px;
}
.review-heading #form-search input[type=text] {
  border: 1px solid #dddddd;
  height: 40px;
}
#form-search input[type='submit'] {
  border: medium none;
  font-size: 0;
  height: 16px;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 16px;
  background-image: url(/img/searchIcon.png);
  background-repeat: no-repeat;
  background-color: transparent;
  text-indent: -1000em;
  padding: 20px;
  background-position: center center;
}
#form-search input.form-control:active,
#form-search input.form-control:focus,
#form-search input[type='submit']:active,
#form-search input[type='submit']:focus {
  outline: none;
  box-shadow: none
}
.ads-wrapper {
  background: {
    image: url("/img/activities.png");
    repeat: no-repeat;
  };
  padding-left: 40px;
}
.paid-padding {
  max-width: 100%;
  width: 33%;
  padding-right: 10px;
}
.paid-padding strong, .created-at strong {
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  color: #8492a6;
}
.paid-padding span, .created-at span {
  display: block;
  color: #3c4858;
  font-size: 20px;
  word-wrap: break-word;
  word-break: break-all;
}
.paid-padding span a {text-decoration: none}
.ads-content .socialM {padding: 5px 0 15px 0}
.btn-back {
  background: {
    image: url("/img/back.png");
    repeat: no-repeat;
    color: transparent;
  };
  border: none;
  display: inline-block;
  width: 18px;
  height: 12px;
  font-size: 0;
  margin-right: 15px;
}
.contentTypeTitle {
  font-size: 28px;
  font-weight: 700;
  color: #3c4858;
}
.contentSubTypeTitle {
  font-size: 15px;
  font-weight: 700;
  color: #6e6e6e;
  margin-left: 20px;
}
.review-details {
  padding-bottom: 30px;
}
.review-details a {
  font-size: 16px;
  color: #2980b9;
  word-break: break-all
}
.review-details p, .review-details strong {
  font-size: 16px;
  margin: 0;
  line-height: 22px;
  color: #3c4858;
}
.keywords-list {
  display: none;
}
.keyword-item {
  font-size: 16px;
  color: #3c4858;
  display: inline-block;
  margin-right: 10px;
}
.key-rate a i {
  font-size: 18px;
  color: #909090;
}
.key-rate span {
  font-size: 14px;
  color: #909090;
  margin-right: 10px;
}
.key-rate a i.fa-thumbs-down,
.key-rate span.up {
  color: #ff4949
}
.key-rate a i.fa-thumbs-up,
.key-rate span.down {
  color: #27ae60
}
a.rbtn {
  margin: 0 5px;
}
textarea.form-control {
  /*border: 2px solid #cdd4d9;*/
  max-width: 1140px;
  width: 100% !important;
}
input[type="radio"], input[type="checkbox"] {box-shadow: none}
.comment .form-review {margin-top: 10px}
.comment a.author {
  font-size: 16px;
  color: #3c4858;
  font-weight: 700;
}
.date {
  font-size: 13px;
  color: #8492a6;
}
.message {
  font-size: 16px;
  color: #41474a;
  margin: 2px 0 6px;
}
.comment {margin-bottom: 20px}
.comment a {
  color: #8492a6;
}
.scores span {color: #c0ccda}
.userDetail {margin-left: 30px}
.userDetail strong {text-transform: uppercase; color: #8492a6; display: block}
.userDetail span {font-size: 24px; color: #3c4858}
.user-container {margin-top: 50px}
.user-container .custom {text-align: center}
.user-container .custom span {color: #8492a6; display: block; text-transform: uppercase; font-weight: 700}
.user-container .custom strong {color: #3c4858; font-size: 48px; font-weight: 700}
.site:first-child {margin-top: 50px}
.site {margin-top: 25px}
.site h4 {margin: 0; font-family: 'Lato', sans-serif;}
.site .key-title {text-transform: uppercase; font-size: 14px; margin-bottom: 10px; display: inline-block}
.site .keyword-item {text-align: center; margin-right: 80px}
.site .key-actions {margin: 0}
.site .key-actions span {display: inline-block; margin-left: 3px}
.site .key-actions i, .site .key-actions span {color: #d3dce6; display: inline-block}
.site .key-actions .space {display: inline-block; margin: 0 3px}
.advertise {margin-top: 10px}
.advertise a.view-review {
  margin: 0 0 15px;
  display: inline-block;
}
.advertise ol li {
  color: #8492a6;
}
.indexing-block label{
  color: #3c4858;
  font-weight: 700;
  display: block;
}
.onlyReg {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 22px;
}
.onlyReg a {margin: 0 10px 10px; display: inline-block}
.campaigns .view-review {display: inline-block;}
.campaigns .ads-content {margin-top: 20px}
.adsCamp strong,
.adsCamp span {font-size: 16px; margin-right: 5px;}
.adsCamp strong {text-transform: uppercase; color: #8492a6}
.adsCamp span {color: #3c4858; font-size: 18px}
.campaigns-body .view-review {margin-left: 20px}
.campaigns-body .text-danger {display: block}
.indexing-subblock {margin-bottom: 20px}
.list-group-item-text {font-size: 16px}
.list-group-item-text b {color: #3c4858}
.submitedApp {margin-bottom: 20px}
.siteDetail {margin: 2px 0}
.siteDetail strong,
.siteDetail span {font-size: 16px}
.siteDetail strong {color: #3c4858}
.siteDetail span {color: #8492a6}
.flexNone {flex: none}
.flexAuto {flex: auto}
.addNewAdd {margin-bottom: 20px}
img.banner {width: 100%}
#loading-msg {
  text-align: center;
  display: none;
  color: #fff;
}
.loading {
  background: #000 none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  position: fixed;
  right: 0;
  top: 0;
}
.loading img {
  height: 160px;
  left: 50%;
  margin: -80px 0 0 -80px;
  position: absolute;
  top: 30%;
  width: 160px;
}
.loading .afterload {
  left: 50%;
  margin-left: -50%;
  width: 100%;
  position: absolute;
  top: 50%;
  margin-top: -70px;
}
canvas{
  display:block;
  vertical-align:bottom;
}
#particles-js{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url("/img/bg.jpg");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.pricingOptions {text-align: center}
.pricingOptions p {color: #3c4858; font-size: 16px; margin: 20px 0; display: inline-block}
.priceOption {
  border-top: 1px solid #d5d5d5;
  border-right: none;
  border-bottom: 1px solid #d5d5d5;
  border-left: 1px solid #d5d5d5;
  padding: 25px;
  z-index: 1;
  /*height: 153px;
  min-width: 188px;*/
  width: 188px;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}
.priceOption:hover {
/*  padding: 45px;
  margin-left: -25px;
  margin-right: -25px;
  height: auto;
  width: 240px;*/
  z-index: 3;
  background: #d5d5d5;
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
/*.priceOption:hover div, .priceOption:hover h4, .priceOption:hover a {
  -moz-transform: scale(0.83);
  -webkit-transform: scale(0.83);
  -o-transform: scale(0.83);
  -ms-transform: scale(0.83);
  transform: scale(0.83);
}*/
.priceOption:last-child {border-right: 1px solid #d5d5d5}
.priceOption div {text-transform: uppercase; color: #8492a6; font-size: 12px;}
.priceOption h4 {color: #3c4858; font-weight: bold;}
.info {padding: 25px; background: #ffffff; position: relative; border: 2px solid #c0ccdb; border-radius: 4px}
.info i {position: absolute; top: -12px; left: -12px; background: #ffffff; font-size: 25px}
.info p {font-size: 16px; margin: 0}
.address {
  padding: 20px;
  background: #eff2f7;
}
.address i {display: block; font-size: 30px}
.address strong {display: block}
.address span {display: block; color: #8492a6}
#map {width: 100%; height: 500px; margin-top: 100px; position: relative}
#overlayMap {display: none}
#overlayMap.active {
  display: block;
  background-image: url("/img/mapOBg.png");
  background-repeat: repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
}
.overContent {position: absolute; left: 50%; top: 50%; margin-top: -75px; margin-left: -150px; display: block}
.overContent h1 {color: #ffffff; font-family: 'YanoneKaffeesatz-Bold'; margin: 0}
.overContent p {color: #d5d5d5}
.overContent a {margin-top: 20px}
.campaign-content {width: 100%}
.campaign-content .campaign-wrapper {float:left; border-radius: 25px; width: 23%; box-shadow: 3px 4px 49px 0px rgba(0, 0, 0, 0.1); margin: 0 2.6666% 2.6666% 0; text-align: center; padding: 20px 20px 40px}
.campaign-content .campaign-wrapper:nth-child(4n) {margin-right: 0}
.campaign-content span {color: #8492a6; font-size: 16px}
.submitedSite, .submitedApp {border-radius: 25px; width: 48%; box-shadow: 3px 4px 49px 0px rgba(0, 0, 0, 0.1); margin: 0 4% 2.6690% 0; float: left; padding: 20px 20px 40px;}
.submitedSite:nth-child(2n), .submitedApp:nth-child(2n) {margin-right: 0}

.bookmark-table {
  width: 100%;
}
.bookmark-category {
  display: block;
  position: relative;
  min-width: 100px;
}
.bookmark-category input {
  display: none;
}
.bookmark-category .bookmark-name {
  font-size: 14px;
  margin: 5px 0;
  padding: 5px 15px 5px 30px;
  border-radius: 50px;
  background: #e5e9f2;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #3c4858;
  transition: .4s;
  white-space: nowrap;
}
input:checked + .bookmark-name {
  color: #fff;
  background-color: #27ae60;
}
input:checked + .bookmark-name:before {
  font-family: FontAwesome;
  content: "\f00c";
  margin-right: 10px;
  position: absolute;
  left: 12px;
}

.edit-bkmk-link {
  font-size: 13px;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: #8492a6;
  white-space: nowrap;
  display: block;
  margin-top: 20px;
}
.edit-bkmk-link:hover {
  color: #27ae60;
}
.btn-delete {
  background: none;
  border: none;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: top;
}
.delete-bkmk.btn-delete {
  vertical-align: middle;
  margin-left: 10px;
}
.delete-bkmk.btn-delete:before {
  font-size: 22px;
}
.edit-bkmk.btn-edit:before {
  display: block;
  height: 14px;
}
.btn-edit {
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
  display: inline-block;
  margin: 0 20px;
}
.btn-edit:hover {text-decoration: none}
.btn-edit:before, .btn-delete:before {
  font-family: FontAwesome;
  color: #c0ccdb;
  font-size: 18px;
  line-height: 18px;
}
.btn-edit:before {content: "\f044";}
.btn-delete:before {content: "\f014";}
.avatar {
  width: 100px;
  height: 100px;
  background: url("/img/responsive.png");
  background-repeat: no-repeat;
  margin: 40px auto;
}
.website-status {margin-right: 20px}
.website-status .status-part-icon {
  background: url("/img/partFriendly.png") no-repeat;
  width: 113px;
  height: 180px;
}
.website-status .status-good-icon {
  background: url("/img/friendly.png") no-repeat;
  width: 113px;
  height: 180px;
}
.website-status .status-bad-icon {
  background: url("/img/notFriendly.png") no-repeat;
  width: 113px;
  height: 180px;
}
.webUrl {
  background: url("/img/web.png") no-repeat;
  background-position: left center;
  color: #8492a6;
  font-size: 16px;
  padding-left: 25px;
  margin: 5px 0;
  word-break: break-all;
}
.websiteRate {margin: 5px 0}
.websiteRate i {
  margin-right: 5px;
  color: #8492a6;
  font-size: 16px;
}
.websiteRate span {
  color: #8492a6;
  font-size: 16px;
}
.websiteOption {
  margin: 15px 0 0 0;
}
.websiteOption label {
  display: block;
  font-weight: 400;
  color: #8492a6;
  text-transform: uppercase;
  margin: 0;
}
.websiteOption span {
  color: #3c4858;
  font-size: 16px;
}
.rum {margin-bottom: 25px}

a:active, a:focus {outline: none}

.modal-backdrop {
  z-index: -1;
}
.rumPopup {margin: 0 5px}
.rum .key-actions {margin: 0}

.fs22 {font-size: 22px}
.modal-body {font-size: 18px}
.popover {
  max-width: 500px !important;
  width: 100%;font-family: 'Montserrat', sans-serif;
}
.popover .popover-title{font-family: 'Montserrat', sans-serif;font-weight: 700}
.keyWrap {padding-right: 30px; position: relative}
.keyWrap .rumPopup {position: absolute; right: 0; top: 0}

@media only screen and (max-width: 768px) {
  .pricingOptions .flex-container {display: table}
  .priceOption {
    display: inline-block;
    width: 50%;
    border: 1px solid #d5d5d5;
  }
}

/**/
.zb-popup {display: none}
.zb-popup.active {
  display: block;
  background-image: url("/img/mapOBg.png");
  background-repeat: repeat;
  position: absolute;
  width: 500px;
  height: 250px;
  top: 0;
  text-align: center;
}
.zb-popup div {position: absolute; left: 50%; top: 50%; margin-top: -75px; margin-left: -150px; display: block}
.zb-popup h1 {color: #ffffff; font-family: 'YanoneKaffeesatz-Bold'; margin: 0}
.zb-popup p {color: #d5d5d5}
.zb-popup a {margin-top: 20px}

.app-score-result {
  margin-bottom: 15px;
}
.survey-content label.control-label  {
  font-size: 16px;
  padding: 0;
  text-align: left;
  margin-bottom: 10px;
}
.survey-content .form-radio {
  font-size: 16px;
  margin-bottom: 15px;
}
.survey-content .form-radio:last-of-type {
  margin-bottom: 0;
}

.search-save-btn.btn {
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 25px;
  background: #e5e9f2;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #3c4858;
}
.search-save-btn.btn:focus,
.search-save-btn.btn:hover {
  background: {
    color: #27ae60
  };
  color: #fff;
  box-shadow: none;
  outline: none;
}
.search-save-btn.btn.active:before {
  font-family: FontAwesome;
  content: "\f00c";
  margin-right: 10px;
}
.search-save-btn.btn.active {
  color: #fff;
  background-color: #27ae60;
  box-shadow: none;
  outline: none;
}
.form-survey .form-group {
  margin-bottom: 30px;
}
.tab-content {
  padding-top: 15px;
}

.hashtags-box .read-more-state {
  display: none;
}
.hashtags-box .read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease;
}
.hashtags-box .read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}
.hashtags-box .read-more-state ~ .read-more-trigger:before {
  content: 'more ...';
}
.hashtags-box .read-more-state:checked ~ .read-more-trigger:before {
  content: 'less ...';
}
.hashtags-box .read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 .5em;
  color: #666;
  font-size: .9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: .25em;
  text-transform: none;
}
.sigs .btn-back {display: none}

.note-toolbar {
  z-index: 200 !important;
}
.popover {
  z-index: 199 !important;
}
.notifications {
  background: #0cca5b;
  color: white;
  padding: 15px;
}
.notifications a {
  color: white;
  text-decoration: underline;
}
.notifications a[data-action="notification-close"] {
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  right: 20px;
}
.modal-body {
  padding: 45px;
}

//searchable selector
div.searchable {
  width: 200px;
  float: left;
  margin: 0 15px;
  display: none;
}

.searchable input {
  width: 100%;
  height: 40px;
  font-size: 15px;
  padding: 10px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  display: block;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center/8px 10px;
}

.searchable ul {
  display: none;
  list-style-type: none;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  border: 1px solid #add8e6;
  border-top: none;
  max-height: 180px;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;

  li {
    padding: 7px 9px;
    border-bottom: 1px solid #e1e1e1;
    cursor: pointer;
    color: #6e6e6e;

    &.selected {
      background-color: #e8e8e8;
      color: #333;
    }
  }
}
.not-bad {
  color: #3B8C4B;
}
.sig-table {
  form {
    display: flex;
    flex-wrap: wrap;

    .bookmark-category {
      margin-right: 10px;
    }

    .follow-sig-cat {
      display: none;
      background-color: #0c85d6;
      color: #FFFFFF;
      border-radius: 50px;
      padding: 6px 15px;
      margin: 5px 10px 10px 0;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.new-message {
  border: 1px solid #add8e6;
}