@media (max-width: 425px) {
  .menuItems {display: none}
  .footer-nav {text-align: left; padding: 15px 0}
  .footer-nav ul {display: block; padding-left: 20px}
  .footer-nav ul li {}
  .c-menu--slide-right {
    width: 300px;
    -webkit-transform: translateX(300px);
    -ms-transform: translateX(300px);
    transform: translateX(300px);
  }
  .trending-btn.btn {margin: 0 30px 20px 0}
  .trending-btn.btn:last-child {margin-bottom: 0}
  .footer_trending.flex-container, .clear_apply.flex-container, .socialM.flex-container, .rate.flex-container {display: block}
  .contentWrapper, .sign-in .contentWrapper, .forgot .contentWrapper {padding: 25px}
  .forgotOption {display: block}
  .forgotOption li:last-child:before {content: ""; padding: 0}
  .socialM.flex-start, .ads-wrapper.flex-container, .submitedApp.flex-container {display: block}
  .view-review.delete-site {height: 40px}
  .loading img,
  .loading .afterload {position: static; margin: 0}
  body {margin: 0 0 145px 0;}
  .button {font-size: 12px;}
  .clearFilters {font-size: 12px;}
  #btn-trending span .fa-cog {font-size: 16px}
  .footer-container, .clear_apply.flex-container {
    -ms-flex-direction: column;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
  }
}