@media (max-width: 768px) {
  .website.search-buttons.justify-content-flex-end {
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .website-status {margin: 0 0 10px 0}
  .website-status .status-good-icon,
  .website-status .status-bad-icon,
  .website-status .status-part-icon {margin: auto;}
  .website-details.flex-container {display: block;}
  .bk-actions-form {
    width: 100%;
  }
}