//
// List groups
// --------------------------------------------------


.list-group.search-results:last-child .list-group-item:last-child {border: 0}
.list-group.search-results .list-group-item:hover {text-decoration: none}

.app-ad {background: #03b039; color: #000; font-weight: bold; padding: 2px 5px}

.app-fad {background: #ff9805; color: #000; font-weight: bold; padding: 2px 5px}


.list-group-item {
  // Disabled state
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    background-color: $list-group-disabled-bg;
    color: $list-group-disabled-color;
    cursor: $cursor-disabled;

    // Force color to inherit for custom content
    .list-group-item-heading {
      color: inherit;
    }
    .list-group-item-text {
      color: $list-group-disabled-text-color;
    }
  }

  // Active class on item itself, not parent
  &.active,
  &.active:hover,
  &.active:focus {
    z-index: 2; // Place active items above their siblings for proper border styling
    color: $list-group-active-color;
    background-color: $list-group-active-bg;
    border-color: $list-group-active-border;

    // Force color to inherit for custom content
    .list-group-item-heading,
    .list-group-item-heading > small,
    .list-group-item-heading > .small {
      color: inherit;
    }
    .list-group-item-text {
      color: $list-group-active-text-color;
    }
  }
}


// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

@include list-group-item-variant(success, $state-success-bg, $state-success-text);
@include list-group-item-variant(info, $state-info-bg, $state-info-text);
@include list-group-item-variant(warning, $state-warning-bg, $state-warning-text);
@include list-group-item-variant(danger, $state-danger-bg, $state-danger-text);


// Custom content options
//
// Extra classes for creating well-formatted content within `.list-group-item`s.

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}
.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}


//result state
.status-loading {
  /*background: url("/images/loading.gif") no-repeat center right -25px;*/
  background: url("/images/emobile-loading-icon.svg") no-repeat center right;
  padding-right: 100px;
}

.inline-ad .status-good, .inline-ad .status-bad, .inline-ad .status-part,
.ad-item.status-good, .ad-item.status-bad, .ad-item.status-part,
.fad-item.status-good, .fad-item.status-bad, .fad-item.status-part {background: none}


.zb-src-logo {
  padding-right: 250px;
}

.zb-src-logo::after {
  background: url("/images/search-logo-small.png") no-repeat 0 0;
  content: "";
  height: 32px;
  position: absolute;
  right: 100px;
  top: 40px;
  width: 150px;
}





#category-hint {
  background: white none repeat scroll 0 0;
  display: none;
  position: absolute;
  width: 90%;
  z-index: 100;
}

.category-hint a {
  display: block;
  text-align: left;
}

.selected-category {
  border: 1px solid #ffffff;
}

.list-group-item.ad-item, .list-group-item.fad-item {padding: 5px 20px 5px 0; min-height: 100%}
.list-group.search-results a.list-group-item.ad-item .list-group-item-heading {color: #a5f127 }
.list-group.search-results a.list-group-item.fad-item .list-group-item-heading {color: #ef9534 }