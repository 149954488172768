@media (max-width: 767px) {
  .menuItems {display: none}
  .footer-nav ul li:first-child::before {content: "•" !important;}
  .form-input {margin-bottom: 25px;}
  .search-tool input[type="submit"] {margin-left: 0; margin-bottom: 25px; display: block}
  .list-group.search-results {margin: 0 5px;}
  .contentWrapper, .sign-in .contentWrapper, .forgot .contentWrapper {position: static; margin: auto}
  .ads-details.flex-start,
  .submitedSite.flex-container,
  .submitedApp.flex-container,
  .rate.flex-start,
  .socialM.flex-start,
  .search-tool.flex-container,
  .flex-start.start-container,
  .flex-box,
  .ads-details.flex-container {
    display: block
  }
  .key-actions.flex-container, .key-rate.flex-container {justify-content: left;}
  .paid-padding {width: 100%; padding: 0}
  .website-details.flexNone {margin-top: 10px}
  .option {margin-bottom: 20px}
  .option:last-child {margin-bottom: 0}
  .submitBtn, #search-form #btn-trending {margin-left: 0}
  .priceOption {width: 100%;}
  .campaign-content .campaign-wrapper {width: 100%; margin-right: 0; float: none}
  .submitedSite, .submitedApp {width: 100%; margin-right: 0}
  .headLinks.flex-container {flex-direction: column;}
  .review-heading #form-search {position: relative; top: 15px; width: 100%}
  .review-heading #form-search .form-control {border: 1px solid; background-position: center center;}
  .review-heading #form-search input[type="submit"].form-control {border: none}
  .review-heading .nav-tabs > li {margin: 0; float: none}
  li.active .bottomActive {display: none}
  .form-comment.form-review textarea.form-control {border: 1px solid}
}